import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import App from "./app";
import { ApiFetchProvider } from "./hooks/useApiFetch";

export const createConciergeApp = (element: HTMLElement) => {
  const root = createRoot(element);
  const rootID = element.getAttribute("id") || undefined;

  const theme = extendTheme({
    styles: {
      global: {
        // styles for the `body`
        body: {
          bg: "#FEFEFE",
          color: "black.500",
        },
        // styles for the `a`
        a: {
          color: "brand.900",
          _hover: {
            textDecoration: "none",
          },
        },
      },
    },
    colors: {
      brand: {
        50: "#9ED5D7",
        100: "#97D2D4",
        200: "#90CFD1",
        300: "#89CCCE",
        400: "#82C9CB",
        500: "#7BC6C8",
        600: "#74C3C5",
        700: "#6DC0C2",
        800: "#66BDC0",
        900: "#5FBABD",
      },
      black: {
        500: "#464646",
      },
    },
  });

  const origin = process.env.REACT_APP_API_URL || "http://admin.concierge.local/api";

  root.render(
    <StrictMode>
      <ChakraProvider theme={theme} cssVarsRoot={rootID}>
        <ApiFetchProvider origin={origin}>
          <Suspense>
            <App />
          </Suspense>
        </ApiFetchProvider>
      </ChakraProvider>
    </StrictMode>
  );
};

const _window = window as any;
_window.createConciergeApp = createConciergeApp;

createConciergeApp(document.getElementById("root") as HTMLElement);

export default createConciergeApp;
