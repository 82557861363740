import { ReactNode, useContext, useState } from "react";

import { Alert, AlertIcon, Button, Center, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";

import { Form, Formik } from "formik";
import { Link } from "react-router-dom";

import { ApiFetchContext, useApiFetchFunction } from "../hooks/useApiFetch";
import { AuthAPI } from "../types/api/auth";
import AuthCard from "./AuthCard";

interface LoginProps {
  endpoint: string;
  children: ReactNode;
}

export const Login = ({ children, endpoint }: LoginProps) => {
  const {
    auth: { token, authError },
    authDispatch,
  } = useContext(ApiFetchContext);
  const apiFetch = useApiFetchFunction();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const fetchToken = async () => {
    setLoading(true);
    try {
      const {
        data: [{ token, user }],
      } = await apiFetch<AuthAPI[]>(endpoint, { username, password });
      authDispatch({ type: "login", token: token, user: user });
    } catch (e) {
      authDispatch({ type: "error", error: `${e}` });
    } finally {
      setLoading(false);
    }
  };

  if (token) {
    return <>{children}</>;
  } else {
    return (
      <AuthCard heading="Log in to your account">
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={() => {
            fetchToken();
          }}
        >
          <Form>
            <Stack spacing="6">
              <Stack spacing="5">
                {authError ? (
                  <Alert status="error">
                    <AlertIcon />
                    {authError}
                  </Alert>
                ) : null}
                <FormControl isDisabled={loading}>
                  <FormLabel htmlFor="username">Username</FormLabel>
                  <Input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                </FormControl>
                <FormControl isDisabled={loading}>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </FormControl>
              </Stack>
              <Stack spacing="6">
                <Button type="submit" colorScheme="brand" isLoading={loading} loadingText={"Logging in"}>
                  Sign in
                </Button>
              </Stack>
              <Center>
                <Link to={`forgotten-password`} onClick={() => authDispatch({type: 'clearError'})}>Forgotten your password?</Link>
              </Center>
            </Stack>
          </Form>
        </Formik>
      </AuthCard>
    );
  }
};
