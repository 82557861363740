import { useEffect, useState } from "react";

import { useApiFetchFunction } from "./useApiFetch";

function useAsyncApiFetch<T>(endpoint: string, args: undefined | Parameters<typeof JSON.stringify>[0] = undefined) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const apiFetch = useApiFetchFunction();
  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: apiData } = await apiFetch<T[]>(endpoint, args);
      setData(apiData);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error };
}

export default useAsyncApiFetch;
