import React from "react";

import { CloseIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Container,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Image,
  Show
} from "@chakra-ui/react";

import { useAuthData, useLogout } from "../hooks/useApiFetch";
import { useSelectedClient } from "../hooks/useSelectedClient";
import { Children } from "../utils/types";
import Breadcrumbs from "./Breadcrumbs";
import cslogo from "../assets/CS_symbol_solid_rgb_50.png";

interface LayoutProps {
  menuItems?: JSX.Element[];
  children?: Children;
}

const Layout = ({ menuItems, children }: LayoutProps) => {
  const doLogout = useLogout();
  const { selectedClient } = useSelectedClient();
  const authData = useAuthData();
  return (
      <>
        <Box backgroundColor={'white'} mb={8}>
          <Container maxW={"100%"} width={1200}>
            <Flex py={3} wrap={"wrap"} width={'100%'} justifyContent={'space-between'} alignItems={'center'} >
              <Flex alignItems={'center'} gap={4}>
                <Image src={cslogo} alt="The Clean Sheet Company Logo" />
              <Show above='sm'>
                  <Box width={"full"} overflowX={"auto"}>
                    <Breadcrumbs />
                  </Box>
                </Show>
              </Flex>
              <Box p={1}>
                <HStack justifyContent={'flex-end'}>
                  <Menu>
                    <MenuButton>
                      <Avatar
                        size={"md"}
                        name={authData.user?.firstname + " " + authData.user?.lastname}
                        src={authData.user?.image}
                      />
                    </MenuButton>
                    <MenuList>
                      {menuItems?.map((item, index) => (
                        <React.Fragment key={item.props.key || index}>{item}</React.Fragment>
                      ))}
                      <MenuDivider />
                      <MenuItem
                        icon={<CloseIcon />}
                        onClick={() => {
                          doLogout();
                        }}
                      >
                        Sign out
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Text>
                    {selectedClient?.name} [{selectedClient?.code}]
                  </Text>
                </HStack>
              </Box>
              <Show below='sm'>
                <Box marginTop={4} width={"full"} overflowX={"auto"}>
                  <Breadcrumbs />
                </Box>
              </Show>
            </Flex>
          </Container>
        </Box>
        
        <Container maxW={"100%"} width={1200}>
          {children}
        </Container>
      </>
  );
};

export default Layout;
