import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { User } from "../types/api/auth";
import { RequestResponse } from "../types/api/base";
import { Client } from "../types/client";
import { matchGuard } from "../utils/types";

type AuthActions =
  | { type: "login"; token: string; user: User }
  | { type: "logout" }
  | { type: "error"; error: string }
  | { type: "clearError" };

interface AuthState {
  token: string | undefined;
  user: User | undefined;
  authError: string | undefined;
  fetchingToken: boolean;
}

interface Context {
  globalKey: number;
  origin: string;
  incGlobalKey: () => void;
  auth: AuthState;
  authDispatch: (action: AuthActions) => void;
  client: Client | undefined;
  setSelectedClient: (client: Client | undefined) => void;
}

export type apiFetchFunction = <T>(
  url: string,
  args?: any,
  headers?: HeadersInit,
  method?: Method | undefined
) => Promise<RequestResponse<T>>;

const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const getLocalStorage = (key: string): string | undefined => {
  return localStorage.getItem(key) || undefined;
};

const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

const getStoredAuth = () => JSON.parse(getLocalStorage("auth") || "{}");
const setStoredAuth = (token: string, user: User) => setLocalStorage("auth", JSON.stringify({ token, user }));
const clearStoredAuth = () => removeLocalStorage("auth");

export const ApiFetchContext = createContext<Context>({
  globalKey: 0,
  origin: "",
  incGlobalKey: () => {
    throw Error("not set");
  },
  auth: {
    token: undefined,
    user: undefined,
    authError: undefined,
    fetchingToken: false,
  },
  authDispatch: () => {
    throw Error("not set");
  },
  client: undefined,
  setSelectedClient: () => {
    throw Error("not set");
  },
});

interface ProviderProps {
  origin?: string;
  children: ReactNode;
}

export const ApiFetchProvider = ({ origin = "", children }: ProviderProps): ReactElement => {
  const [globalKey, setKey] = useState(0);
  const incGlobalKey = () => setKey((k) => k + 1);

  const reducer = useCallback((state: AuthState, action: AuthActions): AuthState => {
    switch (action.type) {
      case "login":
        setStoredAuth(action.token, action.user);
        setSelectedClient(action.user.clients[0]);
        return {
          ...state,
          authError: undefined,
          token: action.token,
          user: action.user,
        };
      case "logout":
        clearStoredAuth();
        return { ...state, authError: undefined, token: undefined, user: undefined };
      case "clearError":
        return { ...state, authError: undefined };
      case "error":
        return { ...state, authError: action.error };
      default:
        return matchGuard(action);
    }
  }, []);

  const [authState, authDispatch] = useReducer(reducer, {
    ...getStoredAuth(),
    authError: undefined,
    fetchingToken: false,
  });

  const selectedClientLocal = localStorage.getItem("selectedClient");
  const [selectedClient, setSelectedClient] = useState(
    selectedClientLocal ? JSON.parse(selectedClientLocal) : authState.user?.clients[0]
  );

  return (
    <ApiFetchContext.Provider
      value={{
        globalKey,
        auth: authState,
        authDispatch,
        client: selectedClient,
        setSelectedClient,
        origin,
        incGlobalKey,
      }}
    >
      {children}
    </ApiFetchContext.Provider>
  );
};

const getOptions = (
  args: Record<string, any> | undefined = undefined,
  method: Method | undefined = undefined,
  headers: HeadersInit = {}
): RequestInit => {
  const _headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  const options =
    args !== undefined
      ? {
          method: method || "post",
          headers: _headers,
          body: JSON.stringify(args),
        }
      : {
          method: method || "get",
          headers: _headers,
        };

  return options as RequestInit;
};

export type Method = "get" | "post" | "put" | "delete";

class FetchError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export const useAuthData = () => {
  const { auth } = useContext(ApiFetchContext);
  return auth;
};

export const useApiFetchFunction = () => {
  const { incGlobalKey, origin, auth, authDispatch } = useContext(ApiFetchContext);
  const authString = "Bearer " + auth.token;
  const apiFetch = async <T,>(
    url: string,
    args: undefined | Parameters<typeof JSON.stringify>[0] = undefined,
    headers: HeadersInit = {},
    method: Method | undefined = undefined
  ): Promise<RequestResponse<T>> => {
    const requestHeaders = auth.token ? { ...headers, Authorization: authString } : headers;
    const options = getOptions(args, method, requestHeaders);
    const cleanResource = url.replace(/^\//, "").replace(`${origin}/`, "");
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("page[limit]", "0");

    const res = await fetch(`${origin}/${cleanResource}?` + urlSearchParams, options);
    if (!res.ok) {
      if (auth.token && res.status === 401) {
        authDispatch({ type: "logout" });
      } else {
        throw new FetchError(res.statusText, res.status);
      }
    }

    const json = (await res.json()) as RequestResponse<T>;

    if (options.method !== "get") {
      incGlobalKey();
    }

    return json;
  };

  return apiFetch;
};

type ApiState<T> =
  | { ok: false; loading: true; response: undefined; errors: string[] }
  | { ok: true; loading: false; response: T; errors: string[] }
  | { ok: false; loading: false; response: undefined; errors: string[] };

type ApiAction<T> = { type: "loaded"; response: T } | { type: "loading" } | { type: "error"; errors: string[] };

export const useApiFetch = <T,>(
  url: string,
  args: Record<string, any> | undefined = undefined,
  headers: HeadersInit = {},
  refreshKey: number = 0
): ApiState<T> => {
  const apiFetch = useApiFetchFunction();
  const { globalKey } = useContext(ApiFetchContext);

  const reducer = useCallback((state: ApiState<T>, action: ApiAction<T>): ApiState<T> => {
    switch (action.type) {
      case "loading":
        return { response: undefined, loading: true, errors: [], ok: false };
      case "loaded":
        return { response: action.response, loading: false, errors: [], ok: true };
      case "error":
        return { response: undefined, loading: false, errors: action.errors, ok: false };
      default:
        return matchGuard(action);
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    response: undefined,
    errors: [],
    ok: false,
  });

  useEffect(() => {
    dispatch({ type: "loading" });
    apiFetch<T>(url, args, headers)
      .then((res) => dispatch({ type: "loaded", response: res.data }))
      .catch((err) => {
        dispatch({ type: "error", errors: [err.message] });
      });
  }, [url, args, refreshKey, globalKey, apiFetch, headers]);

  return state;
};

export const useLogout = () => {
  const { authDispatch } = useContext(ApiFetchContext);
  const navigate = useNavigate();

  return () => {
    localStorage.setItem("selectedClient", "");
    authDispatch({ type: "logout" });
    navigate("/");
  };
};
