import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Spacer,
} from "@chakra-ui/react";

import { Field,FieldProps, Form, Formik } from "formik";

import { Unit } from "../types/api/unit";
import { Stay, staySchema } from "../schemas/stay";
import { MapToStrings } from "../utils/types";
import { ArrowForwardIcon } from "@chakra-ui/icons";

type Values = MapToStrings<Omit<Stay, "Unit">>;

interface StayFormProps {
  unit: Unit;
  onDone: (stay: Stay) => void;
  onCancel: () => void;
}

const StayForm = ({ unit, onDone, onCancel }: StayFormProps) => {
  return (
    <SimpleGrid columns={{ base: 1}} spacing={10} mt={8}>  
      <Box 
        py={{ base: '4', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg="white"
        boxShadow={'md'}
        borderRadius={'xl'}
      >      
      <Box>
        <Heading as='h4' size='md' mb={6}>{unit.code}</Heading>
      </Box>
      <Formik
        initialValues={{
          unitId: `${unit.id}`,
          status: "1",
          numberOfGuests: "2",
          checkInTime: "",
          checkOutTime: "",
          cleaningTime: "",
          reference: "",
        }}
        validationSchema={staySchema}
        onSubmit={(values: Values) => {
          onDone(staySchema.cast(values) as Stay);
        }}
      >
        {(props) => (
          <Form>
            <SimpleGrid columns={{ base: 1, sm: 2}} gap={4}>
              <Field id="checkInTime" name="checkInTime">
                {({ field, form }: FieldProps<string, Values>) => (
                  <FormControl isInvalid={form.errors.checkInTime && form.touched.checkInTime ? true : false}>
                    <FormLabel>Check In</FormLabel>
                    <Input {...field} type="date" />
                    <FormErrorMessage>{form.errors.checkInTime}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field id="checkOutTime" name="checkOutTime">
                {({ field, form }: FieldProps<string, Values>) => (
                  <FormControl isInvalid={form.errors.checkOutTime && form.touched.checkOutTime ? true : false}>
                    <FormLabel>Check Out</FormLabel>
                    <Input {...field} type="date" min={form.values.checkInTime}
                      onChange={(e) => {
                        form.handleChange(e);
                        form.setFieldValue("cleaningTime", `${e.target.value}T10:00`) 
                      }}
                    />
                    <FormErrorMessage>{form.errors.checkOutTime}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field id="cleaningTime" name="cleaningTime">
                {({ field, form }: FieldProps<string, Values>) => (
                  <FormControl isInvalid={form.errors.cleaningTime && form.touched.cleaningTime ? true : false}>
                    <FormLabel>Cleaning Time</FormLabel>
                    <Input {...field} type="datetime-local" min={form.values.checkOutTime} />
                    <FormErrorMessage>{form.errors.cleaningTime}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field id="numberOfGuests" name="numberOfGuests">
                {({ field, form }: FieldProps<string, Values>) => (
                  <FormControl isInvalid={form.errors.numberOfGuests && form.touched.numberOfGuests ? true : false}>
                    <FormLabel>Number of Guests</FormLabel>
                    <Input {...field} type="number" />
                    <FormErrorMessage>{form.errors.numberOfGuests}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field id="reference" name="reference">
                {({ field, form }: FieldProps<string, Values>) => (
                  <FormControl isInvalid={form.errors.reference && form.touched.reference ? true : false}>
                    <FormLabel>Booking Reference</FormLabel>
                    <Input {...field} type="text" />
                    <FormErrorMessage>{form.errors.reference}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Flex minWidth='100%' alignItems='center' justifyContent='flex-end'>
                <Button mt={3} type="submit" colorScheme='brand' rightIcon={<ArrowForwardIcon />}>Next</Button>  
              </Flex>
            </SimpleGrid>
          </Form>
        )}
      </Formik>
      </Box>
      </SimpleGrid>    
  );
};

export default StayForm;
