import { useContext, useState } from "react";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";

import { Form, Formik } from "formik";
import { Link, useSearchParams } from "react-router-dom";

import AuthCard from "../components/AuthCard";
import { ApiFetchContext, useApiFetchFunction } from "../hooks/useApiFetch";

interface SetPasswordProps {
  endpoint: string;
}

const SetPassword = ({ endpoint }: SetPasswordProps) => {
  const {
    auth: { authError },
    authDispatch,
  } = useContext(ApiFetchContext);

  const apiFetch = useApiFetchFunction();

  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState<string>("");

  const requestedToken = searchParams.get("token");

  const clearPassword = () => {
    setPassword("");
    setPasswordConfirm("");
    setLoading(false);
  };

  const postPassword = async () => {
    setLoading(true);
    setRequestSent(false);
    setToken(requestedToken || "");

    if (!password.length) {
      authDispatch({ type: "error", error: "Please enter a password" });
      clearPassword();
      return;
    }

    if (password !== passwordConfirm) {
      authDispatch({ type: "error", error: "Your passwords do not match" });
      clearPassword();
      return;
    }

    try {
      await apiFetch(endpoint, { password, token: requestedToken });
      authDispatch({ type: "clearError" });
    } catch (e) {
      setToken("");
    } finally {
      setLoading(false);
      setRequestSent(true);
      searchParams.delete("token");
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <AuthCard heading="Set your password">
        <Stack spacing="6">
          {((requestSent && !token) || (!requestSent && !requestedToken)) && (
            <>
              <Alert
                status="error"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                p={6}
              >
                <AlertIcon boxSize="40px" m={0} />
                <AlertDescription mt={6}>
                  Your link is invalid or expired.
                  <br />
                  Please <Link to={`/forgotten-password`}>request a new password link</Link>.
                </AlertDescription>
              </Alert>
            </>
          )}

          {requestSent && token && (
            <>
              <Alert
                status="success"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                p={6}
              >
                <AlertIcon boxSize="40px" m={0} />
                <AlertDescription mt={6}>
                  Your password has been set.
                  <br />
                  Please <Link to={`/`}>login here</Link>.
                </AlertDescription>
              </Alert>
            </>
          )}

          {!requestSent && requestedToken && (
            <Formik
              initialValues={{ password: "", passwordConfirm: "" }}
              onSubmit={() => {
                postPassword();
              }}
            >
              <Form>
                <Stack spacing="6">
                  <Stack spacing="5">
                    {authError ? (
                      <Alert status="error">
                        <AlertIcon />
                        {authError}
                      </Alert>
                    ) : null}
                    <FormControl isDisabled={loading}>
                      <FormLabel htmlFor="password">New Password</FormLabel>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        required={true}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <FormControl isDisabled={loading}>
                      <FormLabel htmlFor="passwordConfirm">Confirm Password</FormLabel>
                      <Input
                        id="passwordConfirm"
                        type="password"
                        value={passwordConfirm}
                        required={true}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                      />
                    </FormControl>
                  </Stack>
                  <Stack spacing="6">
                    <Button type="submit" colorScheme="brand" isLoading={loading} loadingText={"Sending request"}>
                      Set password
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            </Formik>
          )}
          <Center>
            <Link to={`/`}>Return to login</Link>
          </Center>
        </Stack>
      </AuthCard>
    </>
  );
};

export default SetPassword;
