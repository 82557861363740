import { apiFetchFunction } from "../hooks/useApiFetch";
import { Changeover, APIChangeover } from "../types/api/changeover";
import { fitStay } from "./stays";

export const getChangovers = async (
  clientCode: string,
  unitCode: string,
  apiFetch: apiFetchFunction,
  path?: string
): Promise<Changeover | undefined> => {
  const suffix = path === undefined ? "" : path;

  const { data } = await apiFetch<APIChangeover[]>(`changeovers/${clientCode}/${unitCode}${suffix}`);
  if (data.length === 1) {
    return {
      current_stay: data[0].current_stay ? fitStay(data[0].current_stay) : undefined,
      upcoming_stay: data[0].upcoming_stay ? fitStay(data[0].upcoming_stay) : undefined,
    };
  }
};
