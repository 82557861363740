import { useContext, useState } from "react";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";

import { Form, Formik } from "formik";
import { Link } from "react-router-dom";

import AuthCard from "../components/AuthCard";
import { ApiFetchContext, useApiFetchFunction } from "../hooks/useApiFetch";
import { SendPasswordResetAPI } from "../types/api/auth";

interface ForgottenPasswordProps {
  endpoint: string;
}

const ForgottenPassword = ({ endpoint }: ForgottenPasswordProps) => {
  const {
    auth: { authError },
  } = useContext(ApiFetchContext);

  const apiFetch = useApiFetchFunction();
  const [username, setUsername] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [tokenExpiry, setTokenExpiry] = useState<Date | null>(null);

  const resetURL = `${window.location.origin}/set-password`;

  const fetchToken = async () => {
    setLoading(true);
    setRequestSent(false);
    setToken("");
    setTokenExpiry(null);
    try {
      const {
        data: [{ token, tokenExpiry }],
      } = await apiFetch<SendPasswordResetAPI[]>(endpoint, {
        username,
        resetURL,
      });

      setToken(token);
      setTokenExpiry(tokenExpiry);
    } catch (e) {
      // Don't indicate to the user that the username cannot be found
    } finally {
      setLoading(false);
      setRequestSent(true);
    }
  };

  return (
    <>
      <AuthCard heading="Request a password reset">
        <Stack spacing="6">
          {requestSent ? (
            <>
              <Alert
                status="success"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                p={6}
              >
                <AlertIcon boxSize="40px" m={0} />
                <AlertDescription mt={6}>
                  If an account with this username exists, a password reset link will be emailed to you.
                </AlertDescription>
              </Alert>
            </>
          ) : (
            <Formik
              initialValues={{ username: "" }}
              onSubmit={() => {
                fetchToken();
              }}
            >
              <Form>
                <Stack spacing="6">
                  <Stack spacing="5">
                    {authError ? (
                      <Alert status="error">
                        <AlertIcon />
                        {authError}
                      </Alert>
                    ) : null}
                    <FormControl isDisabled={loading}>
                      <FormLabel htmlFor="username">Username</FormLabel>
                      <Input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </FormControl>
                  </Stack>
                  <Stack spacing="6">
                    <Button type="submit" colorScheme="brand" isLoading={loading} loadingText={"Sending request"}>
                      Request password reset
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            </Formik>
          )}
          <Center>
            <Link to={`/`}>Return to login</Link>
          </Center>
        </Stack>
      </AuthCard>
      {process.env.NODE_ENV === "development" && requestSent && (
        <Alert status="warning" flexDirection="column" alignItems="center" justifyContent="center" p={6}>
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle m={6} fontSize="lg">
            DEV MODE:
          </AlertTitle>
          <AlertDescription>
            <>
              <strong>Link: </strong>
              <Link to={`/set-password?token=${token}`}>{`${resetURL}?token=${token}`}</Link>
              <br />
              <strong>Token Expiry: </strong> {tokenExpiry}
            </>
          </AlertDescription>
        </Alert>
      )}
    </>
  );
};

export default ForgottenPassword;
