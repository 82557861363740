import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Heading, Text } from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { Stay } from "../schemas/stay";
import ItemList from "./ItemList";
import StayItem from "./StayItem";

interface StayListProps {
  stays: Stay[];
}

const StayList = ({ stays }: StayListProps) => {
  const unitStays = stays.reduce((acc: { [key: string]: Stay[] }, stay) => {
    const key = stay.Unit.code + " " + stay.Unit.name;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(stay);
    return acc;
  }, {});

  for (const key in unitStays) {
    if (unitStays.hasOwnProperty(key)) {
      unitStays[key].sort((a, b) => new Date(a.checkInTime).getTime() - new Date(b.checkInTime).getTime());
    }
  }
  return (
    <Box py={{ base: "4", sm: "8" }} px={{ base: "4", sm: "10" }} bg="white" boxShadow={"md"} borderRadius={"xl"}>
      <Heading as="h4" size="sm" mb={{ base: 2, sm: 6 }}>
        List of bookings
      </Heading>

      <Accordion allowMultiple>
        {Object.entries(unitStays).map(([unitName, stays]) => {
          return (
            <AccordionItem key={unitName}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {unitName}
                      </Box>
                      {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex
                      display={{ base: "none", lg: "flex" }}
                      wrap={{ base: "wrap", lg: "nowrap" }}
                      gap={4}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      pl={3}
                      py={2}
                    >
                      <Text as="b" width={{ base: "100%", lg: "20%" }}>
                        Reference
                      </Text>
                      <Text as="b" width={{ base: "100%", lg: "20%" }} lineHeight={"1.5em"} margin={0}>
                        Check In
                      </Text>
                      <Text as="b" width={{ base: "100%", lg: "20%" }} lineHeight={"1.5em"} margin={0}>
                        Check Out
                      </Text>
                      <Text as="b" width={{ base: "100%", lg: "10%" }}>
                        Status
                      </Text>
                      <Text width={{ base: "100%", lg: "6" }}></Text>
                    </Flex>
                    <ItemList
                      items={stays.map((stay, i) => {
                        return {
                          key: i,
                          item: (
                            <Link to={`${stay.id}`}>
                              <StayItem item={stay} />
                            </Link>
                          ),
                        };
                      })}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </Box>
  );
};

export default StayList;
