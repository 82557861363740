import { ReactNode } from "react";

import { Box, Stack, StackProps } from "@chakra-ui/react";

export interface ItemListItemProps<T> {
  key: string | number;
  item: T;
}

interface ItemListProps<T> extends StackProps {
  items: ItemListItemProps<T>[];
}

const ItemList = <T extends ReactNode>({ items, ...stackProps }: ItemListProps<T>) => {
  return (
    <Stack {...stackProps}>
      {items.map((i) => (
        <Box key={i.key}>{i.item}</Box>
      ))}
    </Stack>
  );
};

export default ItemList;
