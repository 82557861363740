import { useState } from "react";

import { Button, Card, CardBody, Flex, HStack, Stack, Text } from "@chakra-ui/react";

export interface ProductOrderSetProps {
  name: string;
  initialQty: number | null;
  onAdd: () => void;
  onMinus: () => void;
}

const ProductOrderSet = ({ name, initialQty, onAdd, onMinus }: ProductOrderSetProps) => {
  const [qty, setQty] = useState<number | null>(initialQty);
  return (
    <Card boxShadow={"none"}>
      <CardBody p={2} transition={{ ease: "anticipate" }} borderRadius={"var(--card-radius)"}>
        <Flex gap={4}>
          <Stack
            flexGrow={1}
            justifyContent={"space-between"}
            alignItems={{ base: "center", sm: "flex-start" }}
            direction={["row", "column"]}
          >
            <Text lineHeight={"1.5em"} margin={0}>
              {name}
            </Text>
            <HStack>
              <Button
                backgroundColor="brand.200"
                color="white"
                _hover={{ bg: "brand.300" }}
                onClick={() => {
                  onMinus();
                  if (qty !== null) {
                    setQty(qty > 0 ? qty - 1 : 0);
                  }
                }}
                size={"sm"}
              >
                -
              </Button>
              <Text p={2} textAlign="center">
                {qty}
              </Text>
              <Button
                backgroundColor="brand.200"
                color="white"
                _hover={{ bg: "brand.300" }}
                onClick={() => {
                  onAdd();
                  if (qty !== null) {
                    setQty(qty + 1);
                  }
                }}
                size={"sm"}
              >
                +
              </Button>
            </HStack>
          </Stack>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProductOrderSet;
