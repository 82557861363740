import { Box, BreadcrumbLink, Button, Container, Flex, Heading, IconButton, Spacer } from "@chakra-ui/react";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { getStays } from "./api/stays";
import { Login } from "./components/Login";
import StayCollector from "./components/StayCollector";
import ErrorPage from "./error";
import { ApiFetchContext, useApiFetchFunction } from "./hooks/useApiFetch";
import { useSelectedClient } from "./hooks/useSelectedClient";
import Root from "./routes/root";
import StayItem from "./routes/staysItem";
import StaysList from "./routes/staysList";
import ForgottenPassword from "./routes/ForgottenPassword";
import SetPassword from "./routes/SetPassword";
import { Stay } from "./schemas/stay";
import { useContext } from "react";
import ChangeOverList from "./components/housekeeping/ChangeOverList";
import ChangeOver from "./components/housekeeping/ChangeOver";
import { getChangovers } from "./api/changeovers";
import { Changeover } from "./types/api/changeover";
import { ArrowForwardIcon, SmallAddIcon } from "@chakra-ui/icons";
import Collections from "./components/housekeeping/Collections";
import { Collection } from "./types/api/collection";
import Destinations from "./components/Destinations";
import { getCollections } from "./api/collections";

const App = () => {
  const { selectedClient } = useSelectedClient();
  const clientCode = selectedClient?.code || "";
  const apiFetch = useApiFetchFunction();

  const {
    auth: { token },
  } = useContext(ApiFetchContext);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Login endpoint="auth/user">
          <Root />
        </Login>
      ),
      handle: {
        crumb: () => (
          <BreadcrumbLink as={Link} to="/">
            Home
          </BreadcrumbLink>
        ),
      },
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: (
            <Container maxW={"100%"} w={800}>
              <Box p={5} mb={9} bg="white" boxShadow={"md"} borderRadius={"lg"}>
                <Flex alignItems={"center"} wrap={{ base: "wrap", md: "nowrap" }}>
                  <Heading as="h4" size="md" mb={{ base: 4, md: 0 }} width={{ base: "100%", md: "auto" }}>
                    Bookings
                  </Heading>
                  <Flex gap={3} ml={{ base: 0, md: "auto" }} wrap={"wrap"}>
                    <Box flex="1">
                      <Link to={`stays/add`}>
                        <Button colorScheme="brand" margin={"auto"} rightIcon={<SmallAddIcon />} w={"full"}>
                          Add Booking
                        </Button>
                      </Link>
                    </Box>
                    <Box flex="1">
                      <Link to={`stays`}>
                        <Button colorScheme="brand" margin={"auto"} rightIcon={<ArrowForwardIcon />} w={"full"}>
                          View bookings
                        </Button>
                      </Link>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
              <Link to={`housekeeping`}>
                <Box p={5} mb={9} bg="white" boxShadow={"md"} borderRadius={"lg"}>
                  <Flex alignItems={"center"}>
                    <Heading as="h4" size="md" width={{ base: "100%", md: "auto" }} color={"black.500"}>
                      Housekeeping
                    </Heading>
                    <Spacer />
                    <IconButton
                      isRound={true}
                      variant="solid"
                      colorScheme="brand"
                      aria-label="Done"
                      fontSize="20px"
                      icon={<ArrowForwardIcon />}
                    />
                  </Flex>
                </Box>
              </Link>
            </Container>
          ),
        },
        {
          path: "stays",
          handle: {
            crumb: () => (
              <BreadcrumbLink as={Link} to="/stays">
                Bookings
              </BreadcrumbLink>
            ),
          },
          children: [
            {
              index: true,
              element: <StaysList />,
              loader: async () => {
                if (token) {
                  return await getStays(clientCode, apiFetch);
                }

                return [];
              },
            },
            {
              path: "add",
              element: <StayCollector />,
              handle: {
                crumb: () => <>Add a Booking</>,
              },
            },
            {
              path: ":stayId",
              element: <StayItem />,
              loader: async ({ params }) => {
                if (token) {
                  return await getStays(clientCode, apiFetch, "?filter[stays.id]=" + params.stayId);
                }

                return [];
              },
              handle: {
                crumb: (data: Stay[]) =>
                  data && data[0] ? <BreadcrumbLink>Booking {data[0].id}</BreadcrumbLink> : <></>,
              },
            },
          ],
        },
        {
          path: "housekeeping",
          handle: {
            crumb: () => (
              <BreadcrumbLink as={Link} to="/housekeeping">
                Housekeeping
              </BreadcrumbLink>
            ),
          },
          children: [
            {
              index: true,
              element: <Destinations />,
              loader: async () => {
                if (token) {
                  return await getStays(clientCode, apiFetch);
                }
                return [];
              },
            },
            {
              path: "changeovers",
              handle: {
                crumb: () => (
                  <BreadcrumbLink as={Link} to="/housekeeping/changeovers">
                    Changeovers
                  </BreadcrumbLink>
                ),
              },
              children: [
                {
                  index: true,
                  element: <ChangeOverList />,
                },
                {
                  path: ":unitCode",
                  element: <ChangeOver />,
                  loader: async ({ params }) => {
                    if (token && params.unitCode) {
                      return await getChangovers(clientCode, params.unitCode, apiFetch);
                    }
                  },
                  handle: {
                    crumb: (data: Changeover | undefined) => <>{data?.current_stay?.Unit.name}</>,
                  },
                },
              ],
            },
            {
              path: "collections",
              handle: {
                crumb: () => (
                  <BreadcrumbLink as={Link} to="/housekeeping/collections">
                    Collections
                  </BreadcrumbLink>
                ),
              },
              children: [
                {
                  path: ":destinationCode",
                  element: <Collections />,
                  loader: async ({ params }) => {
                    if (token && params.destinationCode) {
                      return await getCollections(clientCode, params.destinationCode, apiFetch);
                      // return {
                      //   destinationCode: params.destinationCode,
                      //   collections: await getCollections(clientCode, params.destinationCode, apiFetch),
                      // };
                    }
                  },
                  handle: {
                    crumb: (data: Collection | undefined) => <>{data?.id}</>,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "forgotten-password",
      children: [
        {
          index: true,
          element: <ForgottenPassword endpoint="auth/send_password_reset" />,
        },
      ],
    },
    {
      path: "set-password",
      children: [
        {
          index: true,
          element: <SetPassword endpoint="auth/password" />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
