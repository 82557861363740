import { useLoaderData } from "react-router-dom";

import { Stay } from "../schemas/stay";
import LinenForm from "../components/LinenForm";
import { useState } from "react";
import { Product } from "../types/api/product";
import { useApiFetchFunction } from "../hooks/useApiFetch";
import { useSelectedClient } from "../hooks/useSelectedClient";
import { Box, Flex, Heading, SimpleGrid, VStack, useToast } from "@chakra-ui/react";
import StayCard from "../components/StayCard";
import VarianceList from "../components/VarianceList";

export default function StayItem() {
  const toast = useToast();
  const [stay] = useLoaderData() as Stay[];
  const { selectedClient } = useSelectedClient();
  const apiFetch = useApiFetchFunction();
  const [products, setProducts] = useState<Product[]>([]);
  const fetchProducts = async () => {
    try {
      if (selectedClient === undefined) {
        console.log("Unknown client.");
      } else {
        const { data } = await apiFetch<Product[]>(`products/${selectedClient?.code}`);
        if (data.length > 0) {
          setProducts(data);
        } else {
          console.log("No products available.");
        }
      }
    } catch (e) {
      console.log("Error looking up products.");
    }
  };

  if (products.length === 0) {
    fetchProducts();
  }

  return products.length > 0 && stay ? (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
      <Box>
        <StayCard stay={stay} unit={stay.Unit} />
      </Box>
      <Box>
        <VStack spacing={2} align="stretch">
          <Box py={{ base: "4", sm: "8" }} px={{ base: "4", sm: "10" }} bg="white" boxShadow={"md"} borderRadius={"xl"}>
            <LinenForm
              stay={stay}
              unit={stay.Unit}
              products={products.map((product) => {
                return { id: product.code, name: product.description };
              })}
              viewOnly={stay.status == 4 ? true : false}
              onDone={async (products) => {
                try {
                  if (stay) {
                    // set status to confirmed
                    stay.status = 3;
                  }
                  const { data } = await apiFetch<Stay[]>(`stays`, {
                    ...stay,
                    products: products,
                  });

                  if (data.length === 1) {
                    const insertedStay: Stay = data[0];
                    toast({
                      title: `Booking confirmed ${insertedStay.id}`,
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                  } else {
                    toast({
                      title: `Sorry there has been a problem updating this booking`,
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                } catch (e) {
                } finally {
                }
              }}
            />
          </Box>
          {stay.productVariances?.length && (
            <Box
              py={{ base: "4", sm: "8" }}
              px={{ base: "4", sm: "10" }}
              bg="white"
              boxShadow={"md"}
              borderRadius={"xl"}
            >
              <Flex minWidth="100%" alignItems="center" justifyContent="space-between">
                <Heading size="sm">Rejected linen</Heading>
              </Flex>
              <VarianceList stay={stay} productVariances={stay.productVariances} />
            </Box>
          )}
        </VStack>
      </Box>
    </SimpleGrid>
  ) : (
    <></>
  );
}
