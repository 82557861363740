import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Card, CardBody, Flex, IconButton, Text } from "@chakra-ui/react";

import { Stay } from "../schemas/stay";

export interface StayItemProps {
  item: Stay;
}

const StayItem = ({ item }: StayItemProps) => {
  return (
    <Card boxShadow={"none"} borderBottom={"1px solid red"} borderBottomColor={"gray.100"} borderRadius={0}>
      <CardBody p={0} pl={3} transition={{ ease: "anticipate" }} borderRadius={"var(--card-radius)"}>
        <Flex
          wrap={{ base: "wrap", lg: "nowrap" }}
          gap={4}
          alignItems={"center"}
          justifyContent={"space-between"}
          py={2}
        >
          <Text width={{ base: "100%", lg: "20%" }}>
            <Text as="b" display={{ base: "inline-block", lg: "none" }}>
              Reference:
            </Text>
            {item.reference}
          </Text>
          <Text width={{ base: "100%", lg: "20%" }} lineHeight={"1.5em"} margin={0}>
            <Text as="b" display={{ base: "inline-block", lg: "none" }}>
              Check In:
            </Text>
            {item.checkInTime.toLocaleDateString("en-GB")}
          </Text>
          <Text width={{ base: "100%", lg: "20%" }} lineHeight={"1.5em"} margin={0}>
            <Text as="b" display={{ base: "inline-block", lg: "none" }}>
              Check Out:
            </Text>
            {item.checkOutTime.toLocaleDateString("en-GB")}
          </Text>
          <Text width={{ base: "100%", lg: "10%" }}>
            <Text as="b" display={{ base: "inline-block", lg: "none" }}>
              Status:
            </Text>
            {item.statusName}
          </Text>
          <IconButton
            minW={0}
            width={"6"}
            height={"6"}
            isRound={true}
            variant="solid"
            colorScheme="brand"
            aria-label="Select stay"
            fontSize={"12px"}
            icon={<ArrowForwardIcon />}
          />
        </Flex>
      </CardBody>
    </Card>
  );
};

export default StayItem;
