import { InferType, date, number, object, string } from "yup";

export const productVarianceListItemSchema = object({
  id: number(),
  productCode: string(),
  productDescription: string(),
  reportedTime: date().label("Reported Time").required(),
  reason: number().required().integer(),
  reasonName: string().optional(),
  qty: number().label("Quantity").required().positive().integer(),
});

export interface ProductVarianceListItem extends InferType<typeof productVarianceListItemSchema> {}
