import { AddIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { Stay } from "../../schemas/stay";
import { ProductListItem } from "../../schemas/productListItem";
import useAsyncApiFetch from "../../hooks/useAsyncApiFetch";
import { Product } from "../../types/api/product";
import { useSelectedClient } from "../../hooks/useSelectedClient";

import { useApiFetchFunction } from "../../hooks/useApiFetch";
import LinenForm from "../LinenForm";

interface ChangeOverRejectsProps {
  stay: Stay;
}

function ChangeOverRejects({ stay }: ChangeOverRejectsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedClient } = useSelectedClient();
  const productApi = useAsyncApiFetch<Product>(`products/${selectedClient?.code}`);
  const toast = useToast();
  const apiFetch = useApiFetchFunction();
  return (
    <>
      <Button type="submit" colorScheme="red" size={"sm"} rightIcon={<AddIcon />} onClick={onOpen}>
        Rejected linen
      </Button>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={"xl"}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject linen on booking - {stay.reference}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {productApi.loading ? (
              <Center width={{ base: "100%" }} marginX={"auto"}>
                <Spinner />
              </Center>
            ) : (
              <>
                <Alert status="warning" variant="left-accent" mb={6}>
                  <AlertIcon />
                  <AlertDescription>
                    Please Note: A photo must be emailed to rejects@thecleansheet.co.uk
                  </AlertDescription>
                </Alert>
                <LinenForm
                  submitButtonText={"Submit rejected linen"}
                  stay={stay}
                  unit={stay.Unit}
                  products={productApi.data.map((product) => {
                    return { id: product.code, name: product.description };
                  })}
                  varianceForm={"reject"}
                  onDone={async (products: ProductListItem[]) => {
                    try {
                      const { data } = await apiFetch<Stay[]>(
                        `linenvariances/${stay.Unit.Destination.code}/${stay.Unit.code}`,
                        {
                          stay_id: stay.id,
                          unit_id: stay.Unit.id,
                          products: products.filter(({ qty }) => {
                            if (qty !== 0) {
                              return true;
                            }
                            return false;
                          }),
                          reason: 1,
                        }
                      );
                      if (data.length === 1) {
                        toast({
                          title: `Rejected linen recorded successfully.`,
                          status: "success",
                          position: "top-right",
                          duration: 5000,
                          isClosable: true,
                        });
                        onClose();
                      } else {
                      }
                    } catch (e) {
                    } finally {
                    }
                  }}
                />
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ChangeOverRejects;
