// This is used to enforce exhaustive switches
export const matchGuard = (_: never, m: string = "Imposible state reached"): never => {
  throw new Error(m);
};

export type KeyofType<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type MapToStrings<T> = {
  [K in keyof T]: string;
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export type WithOnlyRequired<T, K extends keyof T> = Required<Pick<T, K>> & Partial<T>;

export type Children = string | JSX.Element | JSX.Element[]; // | (() => JSX.Element);
