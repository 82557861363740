import { apiFetchFunction } from "../hooks/useApiFetch";
import { Collection } from "../types/api/collection";

export const getCollections = async (
  clientCode: string,
  destinationCode: string,
  apiFetch: apiFetchFunction,
  path?: string
): Promise<Collection[]> => {
  const suffix = path === undefined ? "" : path;
  const { data } = await apiFetch<Collection[]>(`pickups/${clientCode}/${destinationCode}${suffix}`);
  if (data.length > 0) {
    return data.map((collection: Collection) => {
      return {
        id: collection.id,
        Destination: collection.Destination,
        ready_date: collection.ready_date,
        box_qty: collection.box_qty,
        status: collection.status,
        status_name: collection.status_name,
        carrier: collection.carrier,
        tracking_no: collection.tracking_no,
      };
    });
  }

  return [];
};
