import { MenuItem, Text } from "@chakra-ui/react";

import { Outlet, useNavigate } from "react-router-dom";

import Layout from "../components/Layout";
import { useAuthData } from "../hooks/useApiFetch";
import { useSelectedClient } from "../hooks/useSelectedClient";

export default function Root() {
  const authData = useAuthData();
  const { selectedClient, setSelectedClient } = useSelectedClient();
  const navigate = useNavigate();

  if (!authData.user) {
    return <Outlet />;
  }

  //sort clients by name alphabetically
  authData.user?.clients.sort((a, b) => a.name.localeCompare(b.name));
  const clientItems = authData.user?.clients.map((client) => {
    return client.id === selectedClient?.id ? (
      <MenuItem>
        <Text as={"b"}>{client.name}</Text>
      </MenuItem>
    ) : (
      <MenuItem
        onClick={() => {
          navigate("/");
          setSelectedClient(client);
          localStorage.setItem("selectedClient", JSON.stringify(client));
        }}
      >
        {client.name}
      </MenuItem>
    );
  });
  return (
    <Layout menuItems={clientItems}>
      <Outlet />
    </Layout>
  );
}
