import { Box, Heading, SimpleGrid, Button, Center, Spinner, VStack, Flex, Spacer } from "@chakra-ui/react";

import { useSelectedClient } from "../hooks/useSelectedClient";
import { Destination, Unit } from "../types/api/unit";
import useAsyncApiFetch from "../hooks/useAsyncApiFetch";
import { Link } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";

const CollectionList = () => {
  const { selectedClient } = useSelectedClient();
  const unitsApi = useAsyncApiFetch<Unit>(`units/${selectedClient?.code}`);
  const destinations = useAsyncApiFetch<Destination>(`destinations/${selectedClient?.code}`);
  return (
    <Box w="100%" pb={4}>
      <Heading as="h4" size="lg" mb={3}>
        Housekeeping
      </Heading>
      {unitsApi.loading ? (
        <Center width={{ base: "100%" }} marginX={"auto"}>
          <Spinner />
        </Center>
      ) : (
        <>
          <VStack spacing={4} align="stretch">
            {destinations.data.map((destination, i) => {
              return (
                <Box key={i} p={5} bg={"white"} boxShadow={"md"} borderRadius={"lg"}>
                  <Flex alignItems={"center"} mb={{ base: 6, md: 2 }}>
                    <Heading size="sm">{destination.name}</Heading>
                    <Spacer />
                    <Link to={`collections/${destination.code}`}>
                      <Button type="submit" colorScheme="green" size={"sm"} rightIcon={<AddIcon />}>
                        Collections
                      </Button>
                    </Link>
                  </Flex>
                  <SimpleGrid spacing={{ base: 2, md: 4 }} columns={{ base: 1, md: 2, lg: 3 }}>
                    {unitsApi.data.map((unit, i) => {
                      if (unit.code.startsWith(destination.code)) {
                        return (
                          <Box
                            key={i}
                            p={{ base: 2, md: 4 }}
                            borderRadius={"md"}
                            border={"1px"}
                            borderColor={"gray.100"}
                          >
                            <Flex alignItems={"center"} gap={2} wrap={"wrap"}>
                              <Heading size="sm">{unit.name}</Heading>
                              <Spacer />
                              <Link to={`changeovers/${unit.code}`}>
                                <Button size={"sm"} variant="solid" colorScheme="brand">
                                  Changeovers
                                </Button>
                              </Link>
                            </Flex>
                          </Box>
                        );
                      }
                    })}
                  </SimpleGrid>
                </Box>
              );
            })}
          </VStack>
        </>
      )}
    </Box>
  );
};

export default CollectionList;
