import { object, number, string, date, InferType, ref, array } from 'yup';
import { productListItemSchema } from './productListItem';
import { Unit } from '../types/api/unit';
import { productVarianceListItemSchema } from './productVarianceListItem';

export const staySchema = object({
  id: number().positive().integer(),
  unitId: number().required().positive().integer(),
  status: number().required().integer(),
  statusName: string().optional(),
  //Unit: unitSchema,
  reference: string(),
  checkInTime: date().label("Check In Time").required(),
  checkOutTime: date().label("Check Out Time").required().min(ref('checkInTime'), "The check out time must be after the check in time."),
  cleaningTime: date().label("Cleaning Time").required().min(ref('checkOutTime'), "The cleaning time must be after the check out time."),
  numberOfGuests: number().label("Number of Guests").required().positive().max(30).integer(),
  products: array(productListItemSchema).optional(),
  productVariances: array(productVarianceListItemSchema).optional()
});

export interface Stay extends InferType<typeof staySchema> {
  Unit: Unit;
}
