import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Button, Heading, Stack, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { Stay } from "../schemas/stay";
import { Unit } from "../types/api/unit";
import { dateFormatter } from "../utils/dateFormatter";
import { ItemInterface } from "../types/items";
import ProductList from "./ProductList";

interface StayCardProps {
  stay: Stay;
  unit: Unit;
  hideBackLink?: boolean;
  products?: ItemInterface[];
}

const StayCard = ({ stay, unit, products, hideBackLink }: StayCardProps) => {
  return (
    <Box py={{ base: "4", sm: "8" }} px={{ base: "4", sm: "10" }} bg="white" boxShadow={"md"} borderRadius={"xl"}>
      {stay.id != undefined && !hideBackLink && (
        <Link to={`/stays`}>
          <Button mb={8} colorScheme="gray" variant={"outline"} size="sm" leftIcon={<ArrowBackIcon />}></Button>
        </Link>
      )}

      <Heading as="h4" size="sm" mb={{ base: 2, sm: 6 }}>
        Booking Details
      </Heading>
      <Stack>
        <Box>
          <TableContainer>
            <Table variant="simple" size="sm">
              <Tbody>
                {unit === undefined ? (
                  ""
                ) : (
                  <Tr>
                    <Td>Unit</Td>
                    <Td whiteSpace={"break-spaces"}>
                      {unit.code} {unit.name}
                    </Td>
                  </Tr>
                )}
                <Tr>
                  <Td>Booking Reference</Td>
                  <Td>{stay.reference}</Td>
                </Tr>
                <Tr>
                  <Td>Status</Td>
                  <Td>{stay.statusName ? stay.statusName : stay.status == 1 ? "Draft" : ""}</Td>
                </Tr>
                <Tr>
                  <Td>Check In Time</Td>
                  <Td>{dateFormatter(stay.checkInTime)}</Td>
                </Tr>
                <Tr>
                  <Td>Check Out Time</Td>
                  <Td>{dateFormatter(stay.checkOutTime)}</Td>
                </Tr>
                <Tr>
                  <Td>Cleaning Time</Td>
                  <Td>{dateFormatter(stay.cleaningTime)}</Td>
                </Tr>
                <Tr>
                  <Td>Number of Guests</Td>
                  <Td>{stay.numberOfGuests}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        {products && (
          <Box>
            <Heading as="h4" size="sm" my={{ base: 2, sm: 6 }}>
              Expected Linen use
            </Heading>
            <ProductList stay={stay} unit={stay.Unit} products={products} />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default StayCard;
