import { ReactNode } from "react";

import logo from "../assets/logo.png";

import { Container, Heading, Image, Stack, Box } from "@chakra-ui/react";

interface AuthCardProps {
  children: ReactNode;
  heading: string;
}

const AuthCard = ({ children, heading }: AuthCardProps) => {
  return (
    <Container maxW="lg" py={{ base: "12", md: "24" }} px={{ base: "0", sm: "8" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Image src={logo} alt="The Clean Sheet Company Logo" />
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>{heading}</Heading>
          </Stack>
          <Box py={{ base: "0", sm: "8" }} px={{ base: "4", sm: "10" }} bg="white" boxShadow={"md"} borderRadius={"xl"}>
            {children}
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default AuthCard;
