import { useReducer, useState } from "react";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { AbsoluteCenter, Box, Button, Divider, Flex, Heading, SimpleGrid } from "@chakra-ui/react";

import { Field, FieldProps, Form, Formik } from "formik";

import { ProductListItem } from "../schemas/productListItem";
import { Stay, staySchema } from "../schemas/stay";
import { LinenSet, OrderLine } from "../types/api/linen";
import { Unit } from "../types/api/unit";
import { ItemInterface } from "../types/items";
import { matchGuard } from "../utils/types";
import OrderList from "./OrderList";
import ProductOrderItem from "./ProductOrderItem";
import ProductOrderSet from "./ProductOrderSet";
import StayCard from "./StayCard";

interface Values {
  [productCode: string]: number;
}

interface LinenSetCount extends LinenSet {
  qty: number;
}

interface LinenFormProps {
  submitButtonText?: String;
  stay: Stay;
  unit: Unit;
  products: ItemInterface[];
  onDone: (data: ProductListItem[]) => void;
  varianceForm?: "variance" | "reject";
  viewOnly?: boolean;
}

interface OrderState {
  linenSets: LinenSetCount[];
  items: OrderLine[];
}

const LinenForm = ({ submitButtonText, stay, unit, products, onDone, varianceForm, viewOnly }: LinenFormProps) => {
  const resolveItemList = (linenSets: LinenSet[]): ItemInterface[] => {
    const productCodes = linenSets.reduce((acc: string[], cur) => {
      return [...acc, ...cur.components.map((line) => line.code)];
    }, []);
    return products.filter((item) => productCodes.includes(`${item.id}`));
  };

  const resolveItems = (linenSets: LinenSetCount[]): OrderLine[] => {
    const productList: OrderLine[] = resolveItemList(linenSets).map((i) => {
      return { product: i, quantity: 0 };
    });

    return linenSets.reduce((acc, cur) => {
      cur.components.forEach((line) => {
        const accIndex = acc.findIndex((item) => {
          return item.product.id === line.code;
        });
        if (accIndex === -1) {
          alert("Runtime error: Set contains product that doesn't exist");
        }
        acc[accIndex].quantity += line.quantity * cur.qty;
      });

      return acc;
    }, productList);
  };

  type OrderActions =
    | { type: "addItem"; item: ItemInterface }
    | { type: "removeItem"; item: ItemInterface }
    | { type: "addSet"; set: LinenSet }
    | { type: "removeSet"; set: LinenSet };

  const initialLinenSets = unit.LinenSets.reduce((acc, cur) => {
    if (!cur.is_internal) {
      acc.push({ ...cur, qty: stay.products === undefined ? cur.default_qty : 0 });
    }
    return acc;
  }, [] as LinenSetCount[]);

  const initialItems = resolveItems(initialLinenSets);
  if (stay.products) {
    stay.products.forEach((stayProduct) => {
      const itemIndex = initialItems.findIndex((line) => line.product.id === stayProduct.product_code);
      if (initialItems[itemIndex]) {
        initialItems[itemIndex].quantity = varianceForm ? 0 : stayProduct.qty;
      } else {
        const [item] = products.filter((item) => item.id === stayProduct.product_code);
        initialItems.push({ product: item, quantity: varianceForm === "reject" || varianceForm === "variance" ? 0 : stayProduct.qty });
      }
    });
  }

  const [{ linenSets, items }, orderDispatch] = useReducer(
    (state: OrderState, action: OrderActions) => {
      let newItems;
      switch (action.type) {
        case "addItem":
          newItems = state.items.map((item) => {
            if (item.product.id === action.item.id) {
              return { ...item, quantity: item.quantity + 1 };
            }
            return { ...item };
          });
          return { ...state, items: newItems };
        case "removeItem":
          newItems = state.items.map((item) => {
            if (item.product.id === action.item.id) {
              return { ...item, quantity: varianceForm === "variance" ? item.quantity - 1 : Math.max(item.quantity - 1, 0) };
            }
            return { ...item };
          });
          return { ...state, items: newItems };
        case "addSet":
          newItems = resolveItems([{ ...action.set, qty: 1 }]).reduce(
            (acc, cur) => {
              const accIndex = acc.findIndex((item) => item.product.id === cur.product.id);
              acc[accIndex].quantity += cur.quantity;
              return acc;
            },
            state.items.map((item) => ({ ...item }))
          );
          return { ...state, items: newItems };
        case "removeSet":
          newItems = resolveItems([{ ...action.set, qty: 1 }]).reduce(
            (acc, cur) => {
              const accIndex = acc.findIndex((item) => item.product.id === cur.product.id);
              acc[accIndex].quantity = Math.max(acc[accIndex].quantity - cur.quantity, 0);
              return acc;
            },
            state.items.map((item) => ({ ...item }))
          );
          return { ...state, items: newItems };
        // default:
        //   return matchGuard(action);
      }
    },
    {
      linenSets: initialLinenSets,
      items: initialItems,
    }
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={items.reduce((acc: Values, cur) => {
        acc[cur.product.id.toString()] = cur.quantity;
        return acc;
      }, {})}
      onSubmit={(values) => {
        const productOrder: ProductListItem[] = Object.keys(values).map((k) => {
          return {
            product_code: k,
            qty: values[k],
          };
        });
        onDone(productOrder);
      }}
    >
      {(props) => (
        <Form>
          {!varianceForm && (
            <>
              <Heading as="h4" size="sm" mb={6}>
                What linen do you expect to be used during this booking?
              </Heading>
              {!viewOnly && (
                <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={{ base: 2, sm: 4, md: 8 }} mb={5}>
                  {linenSets.map((set, i) => {
                    return (
                      <ProductOrderSet
                        key={i}
                        name={set.name}
                        initialQty={stay.products === undefined ? set.default_qty : null}
                        onAdd={() => {
                          orderDispatch({
                            type: "addSet",
                            set: set,
                          });
                          //form.setFieldValue(field.name, field.value + 1);
                        }}
                        onMinus={() => {
                          orderDispatch({
                            type: "removeSet",
                            set: set,
                          });
                          //form.setFieldValue(field.name, field.value + 1);
                        }}
                      />
                    );
                  })}
                </SimpleGrid>
              )}
              {items.length ? (
                <Box position="relative" padding="10">
                  <Divider />
                  <AbsoluteCenter bg="white" px="4">
                    Selected linen
                  </AbsoluteCenter>
                </Box>
              ) : (
                ""
              )}
            </>
          )}

          <OrderList
            items={items
              .sort((a, b) => {
                if (a.product.id === b.product.id) return 0;
                return a.product.id < b.product.id ? -1 : 1;
              })
              .map((i) => ({
                key: i.product.id,
                item: (
                  <Field id={i.product.id.toString()} name={i.product.id.toString()}>
                    {({ field, form }: FieldProps<number, Values>) => (
                      <ProductOrderItem
                        key={i.product.id}
                        item={i.product}
                        field={field}
                        hideButtons={viewOnly}
                        onAdd={() => {
                          orderDispatch({
                            type: "addItem",
                            item: i.product,
                          });
                          //form.setFieldValue(field.name, field.value + 1);
                        }}
                        onMinus={() => {
                          orderDispatch({
                            type: "removeItem",
                            item: i.product,
                          });
                          //form.setFieldValue(field.name, (field.value > 0) ? field.value - 1 : 0);
                        }}
                      />
                    )}
                  </Field>
                ),
              }))}
          />
          <Flex minWidth="100%" alignItems="center" justifyContent="flex-end" mt={9}>
            <Button type="submit" colorScheme="brand" rightIcon={<ArrowForwardIcon />}>
              {submitButtonText ? submitButtonText : "Confirm Booking"}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default LinenForm;
