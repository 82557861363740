import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Center,
  Flex,
  FormErrorMessage,
  Heading,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";

import { useApiFetchFunction } from "../../hooks/useApiFetch";
import { useSelectedClient } from "../../hooks/useSelectedClient";
import { Unit } from "../../types/api/unit";
import { getChangovers } from "../../api/changeovers";
import { Changeover } from "../../types/api/changeover";
import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { dateFormatter } from "../../utils/dateFormatter";

const ChangeOverItem = ({ unit }: { unit: Unit }) => {
  const [changeover, setChangeover] = useState<Changeover | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");

  const { selectedClient } = useSelectedClient();
  const apiFetch = useApiFetchFunction();
  const fetchChangeovers = async () => {
    setFormError("");
    setIsLoading(true);
    try {
      if (selectedClient === undefined) {
        setFormError("Unknown client.");
      } else {
        const changeover = await getChangovers(selectedClient.code, unit.code, apiFetch);
        setChangeover(changeover);
      }
    } catch (e) {
      setFormError("Error looking up changeover.");
    } finally {
      setIsLoading(false);
    }
  };

  if (changeover === undefined && formError === "" && !isLoading) {
    setIsLoading(true);
    fetchChangeovers();
  }

  return (
    <Box mb={8}>
      <Heading as="h4" size="lg" mb={3}>
        {unit.name}
      </Heading>
      <Box py={{ base: "4", sm: "8" }} px={{ base: "4", sm: "10" }} bg="white" boxShadow={"md"} borderRadius={"xl"}>
        {isLoading ? (
          <Center width={{ base: "100%" }} marginX={"auto"}>
            <Spinner />
          </Center>
        ) : (
          <>
            <FormErrorMessage>{formError}</FormErrorMessage>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {[changeover?.current_stay, changeover?.upcoming_stay].map((stay, i) => {
                if (stay === undefined)
                  return (
                    <Box>
                      <Heading as="h4" size="sm" mb={{ base: 2, sm: 6 }}>
                        There is no {i == 0 ? "Current" : "Upcoming"} stay booked.
                      </Heading>
                    </Box>
                  );
                return (
                  <Box>
                    <Heading as="h4" size="sm" mb={{ base: 2, sm: 6 }}>
                      {i == 0 ? "Current" : "Upcoming"} Booking
                    </Heading>

                    <TableContainer width={{ base: "100%", md: "90%" }}>
                      <Table variant="simple" size="sm">
                        <Tbody>
                          <Tr>
                            <Td px={0}>Booking Reference</Td>
                            <Td>{stay?.reference}</Td>
                          </Tr>
                          <Tr>
                            <Td px={0}>Number of Guests</Td>
                            <Td>{stay?.numberOfGuests}</Td>
                          </Tr>
                          {i == 0 && stay?.checkOutTime ? (
                            <>
                              <Tr>
                                <Td px={0}>Check Out Date</Td>
                                <Td>{dateFormatter(stay?.checkOutTime)}</Td>
                              </Tr>
                              <Tr>
                                <Td px={0}>Cleaning Time</Td>
                                <Td>{dateFormatter(stay?.cleaningTime)}</Td>
                              </Tr>
                            </>
                          ) : i == 1 && stay?.checkInTime ? (
                            <Tr>
                              <Td px={0}>Check In Date</Td>
                              <Td>{dateFormatter(stay?.checkInTime)}</Td>
                            </Tr>
                          ) : (
                            " "
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                );
              })}
            </SimpleGrid>
            {changeover?.current_stay != undefined ? (
              <Flex minWidth="100%" alignItems="center" justifyContent="flex-end" mt={9}>
                <Link to={`${unit.code}`}>
                  <Button type="submit" colorScheme="brand" rightIcon={<ArrowForwardIcon />}>
                    View
                  </Button>
                </Link>
              </Flex>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChangeOverItem;
