import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { LinenSet, OrderLine } from "../types/api/linen";
import { Unit } from "../types/api/unit";
import { ItemInterface } from "../types/items";
import { Stay } from "../schemas/stay";

interface Values {
  rejectedProductCode: string;
}

interface ProductListProps {
  unit: Unit;
  stay: Stay;
  products: ItemInterface[];
}

interface LinenSetCount extends LinenSet {
  qty: number;
}

const ProductList = ({ unit, products, stay }: ProductListProps) => {
  const resolveItemList = (linenSets: LinenSet[]): ItemInterface[] => {
    const productCodes = linenSets.reduce((acc: string[], cur) => {
      return [...acc, ...cur.components.map((line) => line.code)];
    }, []);
    return products.filter((item) => productCodes.includes(`${item.id}`));
  };

  const resolveItems = (linenSets: LinenSetCount[]): OrderLine[] => {
    const productList: OrderLine[] = resolveItemList(linenSets).map((i) => {
      return { product: i, quantity: 0 };
    });

    return linenSets.reduce((acc, cur) => {
      cur.components.forEach((line) => {
        const accIndex = acc.findIndex((item) => {
          return item.product.id === line.code;
        });
        if (accIndex === -1) {
          alert("Runtime error: Set contains product that doesn't exist");
        }
        acc[accIndex].quantity += line.quantity * cur.qty;
      });

      return acc;
    }, productList);
  };

  const initialLinenSets = unit.LinenSets.reduce((acc, cur) => {
    if (!cur.is_internal) {
      acc.push({ ...cur, qty: stay.products === undefined ? cur.default_qty : 0 });
    }
    return acc;
  }, [] as LinenSetCount[]);

  const initialItems = resolveItems(initialLinenSets);
  if (stay.products) {
    stay.products.forEach((stayProduct) => {
      const itemIndex = initialItems.findIndex((line) => line.product.id === stayProduct.product_code);
      if (initialItems[itemIndex]) {
        initialItems[itemIndex].quantity = stayProduct.qty;
      } else {
        const [item] = products.filter((item) => item.id === stayProduct.product_code);
        initialItems.push({ product: item, quantity: stayProduct.qty });
      }
    });
  }

  return (
    <TableContainer>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Product</Th>
            <Th>Qty</Th>
          </Tr>
        </Thead>
        <Tbody>
          {initialItems
            .sort((a, b) => {
              if (a.product.id === b.product.id) return 0;
              return a.product.id < b.product.id ? -1 : 1;
            })
            .map((item, index) => (
              <Tr key={index}>
                <Td>{item.product.name}</Td>
                <Td>{item.quantity}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ProductList;
