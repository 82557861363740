import { InferType, date, number, object } from "yup";

const minCollectionDate = new Date();
minCollectionDate.setDate(minCollectionDate.getDate() + 1);

export const collectionListItemSchema = object({
  readyDate: date()
    .label("Collection Date")
    .required()
    .min(
      minCollectionDate.toISOString().slice(0, minCollectionDate.toISOString().lastIndexOf("T")),
      "Minimum collection date is 24 hours"
    ),
  boxQty: number().label("Number of boxes").required().positive().integer(),
});

export interface CollectionListItem extends InferType<typeof collectionListItemSchema> {}
