import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Breadcrumb, BreadcrumbItem } from "@chakra-ui/react";

import { UIMatch, useMatches } from "react-router-dom";

const Breadcrumbs = () => {
  let matches = useMatches();
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match: UIMatch<any, any>) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match: UIMatch<any, any>) => match.handle.crumb(match.data));

  return (
    <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
      {crumbs.map((crumb, index) => (
        <BreadcrumbItem key={index} whiteSpace={"nowrap"}>{crumb}</BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
