import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select } from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { CollectionListItem, collectionListItemSchema } from "../schemas/collectionListItem";
import { MapToStrings } from "../utils/types";

type Values = MapToStrings<CollectionListItem>;

interface CollectionFormProps {
  onDone: (collection: CollectionListItem) => void;
}

const minCollectionDate = new Date();
minCollectionDate.setDate(minCollectionDate.getDate() + 1);

function getNextWeekday(date: Date): Date {
  var tomorrow = new Date(date.setDate(date.getDate() + 1));
  return tomorrow.getDay() % 6 ? tomorrow : getNextWeekday(tomorrow);
}

const CollectionsForm = ({ onDone }: CollectionFormProps) => {
  const numberOfBoxes = Array.from({ length: 15 }, (_, i) => i + 1);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        readyDate: getNextWeekday(new Date()).toISOString().split("T")[0],
        boxQty: "1",
      }}
      validationSchema={collectionListItemSchema}
      onSubmit={(values: Values) => {
        onDone(collectionListItemSchema.cast(values) as CollectionListItem);
      }}
    >
      {() => (
        <Box>
          <Form>
            <Field id="readyDate" name="readyDate">
              {({ field, form }: FieldProps<string, Values>) => (
                <FormControl mb={4} isInvalid={form.errors.readyDate && form.touched.readyDate ? true : false}>
                  <FormLabel>Collection Date</FormLabel>
                  <Input
                    {...field}
                    type="date"
                    min={minCollectionDate.toISOString().slice(0, minCollectionDate.toISOString().lastIndexOf("T"))}
                  />
                  <FormErrorMessage>{form.errors.readyDate}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field id="boxQty" name="boxQty">
              {({ field, form }: FieldProps<string, Values>) => (
                <FormControl isInvalid={form.errors.boxQty && form.touched.boxQty ? true : false}>
                  <FormLabel>Number of Boxes</FormLabel>
                  <Select {...field}>
                    {numberOfBoxes.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.boxQty}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex minWidth="100%" alignItems="center" justifyContent="flex-end" mt={9}>
              <Button type="submit" colorScheme="brand" rightIcon={<ArrowForwardIcon />}>
                Submit
              </Button>
            </Flex>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default CollectionsForm;
