import { Box, Center, Spinner } from "@chakra-ui/react";

import { useSelectedClient } from "../../hooks/useSelectedClient";
import { Unit } from "../../types/api/unit";
import ChangeOverItem from "./ChangeOverItem";
import useAsyncApiFetch from "../../hooks/useAsyncApiFetch";

const ChangeOverList = () => {
  const { selectedClient } = useSelectedClient();
  const unitsApi = useAsyncApiFetch<Unit>(`units/${selectedClient?.code}`);

  return (
    <Box w="100%" pb={4}>
      {unitsApi.loading ? (
        <Center width={{ base: "100%" }} marginX={"auto"}>
          <Spinner />
        </Center>
      ) : (
        <Box width={{ base: "100%" }} marginX={"auto"}>
          {unitsApi.data.map((unit, i) => {
            return <ChangeOverItem key={i} unit={unit} />;
          })}
        </Box>
      )}
    </Box>
  );
};

export default ChangeOverList;
