import { Button, Card, CardBody, Flex, HStack, Spacer, Stack, Text, VisuallyHiddenInput } from "@chakra-ui/react";

import { FieldInputProps, FormikProps } from "formik";

import { ItemInterface } from "../types/items";

export interface ProductOrderItemProps<T extends ItemInterface> {
  item: T;
  field: FieldInputProps<number>;
  onAdd: () => void;
  onMinus: () => void;
  hideButtons?: boolean;
}

const ProductOrderItem = <T extends ItemInterface>({
  item,
  field,
  onAdd,
  onMinus,
  hideButtons,
}: ProductOrderItemProps<T>) => {
  return (
    <Card boxShadow={"none"}>
      <CardBody p={0} pl={3} transition={{ ease: "anticipate" }} borderRadius={"var(--card-radius)"}>
        <Flex gap={4}>
          <Stack p={2}>
            <Text lineHeight={"1.5em"} margin={0}>
              {item.name}
            </Text>
            {item.description ? (
              <Text fontSize={"0.75em"} lineHeight={"1.5em"} margin={0}>
                {item.description}
              </Text>
            ) : null}
          </Stack>
          <Spacer />
          <HStack mr={1} maxW="200px">
            {!hideButtons && (
              <Button
                backgroundColor="brand.200"
                color="white"
                _hover={{ bg: "brand.300" }}
                onClick={() => {
                  onMinus();
                }}
                size={"sm"}
              >
                -
              </Button>
            )}
            <VisuallyHiddenInput {...field} />
            <Text p={2} minW={"35px"} textAlign="center">
              {field.value}
            </Text>
            {!hideButtons && (
              <Button
                backgroundColor="brand.200"
                color="white"
                _hover={{ bg: "brand.300" }}
                onClick={() => {
                  onAdd();
                }}
                size={"sm"}
              >
                +
              </Button>
            )}
          </HStack>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProductOrderItem;
