import { Box, Button, Center, Flex, HStack, Heading, SimpleGrid, Spinner, useToast, Text } from "@chakra-ui/react";
import { Changeover } from "../../types/api/changeover";
import { Link, useLoaderData } from "react-router-dom";
import { useSelectedClient } from "../../hooks/useSelectedClient";
import useAsyncApiFetch from "../../hooks/useAsyncApiFetch";
import { Product } from "../../types/api/product";
import ChangeOverRejects from "./ChangeOverRejects";
import StayCard from "../StayCard";
import ChangeOverVariances from "./ChangeOverVariances";
import VarianceList from "../VarianceList";

const ChangeOver = () => {
  const changeover = useLoaderData() as Changeover;
  const { selectedClient } = useSelectedClient();
  const productApi = useAsyncApiFetch<Product>(`products/${selectedClient?.code}`);
  const toast = useToast();

  const products = productApi.data.map((product) => {
    return { id: product.code, name: product.description };
  });

  return productApi.loading ? (
    <Center width={{ base: "100%" }} marginX={"auto"}>
      <Spinner />
    </Center>
  ) : (
    <>
      {changeover.current_stay ? (
        <Box w="100%" pb={4}>
          <Flex alignItems={"Center"} justifyContent={"space-between"} gap={3} mb={6} wrap={"wrap"}>
            <Heading as="h4" size="lg">
              {changeover.current_stay.Unit.name} Changeover
            </Heading>
            <HStack spacing={4}>
              {changeover.current_stay && <ChangeOverRejects stay={changeover.current_stay} />}
              {changeover.current_stay && (
                <ChangeOverVariances
                  stay={changeover.current_stay}
                  unit={changeover.current_stay.Unit}
                  products={products}
                />
              )}
            </HStack>
          </Flex>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mb={5}>
            <StayCard
              stay={changeover.current_stay}
              unit={changeover.current_stay.Unit}
              hideBackLink={true}
              products={products}
            />
            <Box>
              <Box
                py={{ base: "4", sm: "8" }}
                px={{ base: "4", sm: "10" }}
                bg="white"
                boxShadow={"md"}
                borderRadius={"xl"}
                height={"full"}
              >
                <Heading as="h4" size="sm" mb={{ base: 2, sm: 6 }}>
                  Recorded linen variances
                </Heading>
                {changeover.current_stay.productVariances && changeover.current_stay.productVariances?.length !== 0 ? (
                  <VarianceList
                    stay={changeover.current_stay}
                    productVariances={changeover.current_stay.productVariances}
                  />
                ) : (
                  <Text>No variances recorded</Text>
                )}
              </Box>
            </Box>
          </SimpleGrid>
          <Flex alignItems={"Center"} justifyContent={"flex-end"} gap={3} mb={6}>
            <Link to={`/`}>
              <Button
                colorScheme="brand"
                onClick={() =>
                  toast({
                    title: `Change updates recorded successfully.`,
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                  })
                }
              >
                Complete changeover
              </Button>
            </Link>
          </Flex>
        </Box>
      ) : (
        <Box w="100%" p={5} bg={"white"} boxShadow={"md"} borderRadius={"lg"}>
          There are currently no upcoming stays
        </Box>
      )}
    </>
  );
};

export default ChangeOver;
