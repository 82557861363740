import { useLoaderData } from "react-router-dom";
import StayList from "../components/StayList";
import { Stay } from "../schemas/stay";

const StaysList = () => {
  const stays = useLoaderData() as Stay[];
  return (
    <StayList stays={stays} />
  );
}

export default StaysList;
