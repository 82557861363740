import { Card, CardBody, Flex, IconButton, Stack, Text } from "@chakra-ui/react";
import { Unit } from "../types/api/unit";
import { AddIcon } from "@chakra-ui/icons";

export interface UnitItemProps {
  item: Unit;
  onClick: (unit: Unit) => void;
}

const UnitItem = ({ item, onClick }: UnitItemProps) => {
  return (
    <Card
      onClick={() => {
        onClick(item);
      }}
    >
      <CardBody p={4} transition={{ ease: "anticipate" }} borderRadius={"var(--card-radius)"} cursor={'pointer'}>
        <Flex gap={4} justifyContent="space-between" alignItems={"center"}>
          <Stack>
            <Text margin={0}>
              {item.name}
            </Text>
          </Stack>
          <IconButton
              minW={0}
              width={"6"}
              height={"6"}
              isRound={true}
              variant='solid'
              colorScheme="brand"
              aria-label='Select stay'
              fontSize={"12px"}
              icon={<AddIcon />}
            />
        </Flex>
      </CardBody>
    </Card>
  );
};

export default UnitItem;
