import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ProductVarianceListItem } from "../schemas/productVarianceListItem";
import { dateFormatter } from "../utils/dateFormatter";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Product } from "../types/api/product";
import { Stay } from "../schemas/stay";
import useAsyncApiFetch from "../hooks/useAsyncApiFetch";
import { useSelectedClient } from "../hooks/useSelectedClient";
import { useApiFetchFunction } from "../hooks/useApiFetch";
import EditVariance from "./EditVariance";

interface VarianceListProps {
  stay: Stay;
  productVariances: ProductVarianceListItem[];
}

const VarianceList = ({ stay, productVariances }: VarianceListProps) => {
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [activeVarianceProduct, setActiveVarianceProduct] = useState<ProductVarianceListItem>();
  const [isDeleting, setIsDeleting] = useState<boolean>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { selectedClient } = useSelectedClient();
  const apiFetch = useApiFetchFunction();
  const productApi = useAsyncApiFetch<Product>(`products/${selectedClient?.code}`);
  const toast = useToast();

  return (
    <>
      <Box minWidth="100%" mt={5}>
        <VStack spacing={2} align="stretch">
          {productVariances.map((product, index) => (
            <Box key={index} p={"2.5"} borderRadius={"md"} border={"1px"} borderColor={"gray.100"}>
              <Flex alignItems={"flex-start"} justifyContent={"space-between"}>
                <Flex fontSize={"sm"} display={"flex"} gap={1}>
                  <Text fontWeight={"extrabold"}>{product.qty}</Text> {product.productDescription}
                  
                </Flex>
                <Flex alignItems={"center"}>
                  <Box
                    color="white"
                    backgroundColor={product.reason == 0 ? "blue.500" : "red.500"}
                    textTransform={"capitalize"}
                    fontWeight={"bold"}
                    py={"2px"}
                    px={"5px"}
                    fontSize={"xs"}
                    borderRadius={"4"}
                  >
                    {product.reasonName}
                  </Box>

                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Variance Options"
                      icon={
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="var(--chakra-colors-gray-500)"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      }
                      size={"xs"}
                      variant="link"
                    />
                    <MenuList>
                      <MenuItem
                        icon={<EditIcon />}
                        onClick={() => {
                          setActiveVarianceProduct(product);
                          onEditOpen();
                        }}
                      >
                        Edit variance
                      </MenuItem>
                      <MenuItem
                        icon={<DeleteIcon />}
                        onClick={() => {
                          setActiveVarianceProduct(product);
                          onDeleteOpen();
                        }}
                      >
                        Delete variance
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>
              {product.reportedTime instanceof Date && !isNaN(product.reportedTime.getTime()) ? (
                <Text fontSize={"xs"} color={"GrayText"}>
                  {dateFormatter(product.reportedTime)}
                </Text>
              ) : (
                <Text fontSize={"xs"} color={"GrayText"}>
                  No time recorded
                </Text>
              )}
            </Box>
          ))}
        </VStack>
      </Box>
      <Modal
        onClose={onEditClose}
        isOpen={isEditOpen}
        isCentered
        size={"xl"}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit linen variance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {activeVarianceProduct && (
              <EditVariance
                unit={stay.Unit}
                product={activeVarianceProduct}
                isLoading={isEditing}
                onDone={async (editVariance) => {
                  setIsEditing(true);
                  try {
                    const { data } = await apiFetch<Stay[]>(
                      `linenvariances/${stay.Unit.Destination.code}/${stay.Unit.code}`,
                      {
                        stay_id: stay.id,
                        unit_id: stay.Unit.id,
                        reason: activeVarianceProduct.reason,
                        products: [
                          {
                            id: activeVarianceProduct.id,
                            product_code: editVariance.product_code,
                            qty: editVariance.qty,
                          },
                        ],
                      }
                    );
                    if (data.length === 1) {
                      toast({
                        title: `Linen variance updated successfully.`,
                        status: "success",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                      });
                      onEditClose();
                    } else {
                      toast({
                        title: `Sorry there has been an error updating the variance, please try again.`,
                        status: "error",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                  } catch (e) {
                    toast({
                      title: `Sorry there has been an error updating the variance, please try again.`,
                      status: "error",
                      position: "top-right",
                      duration: 5000,
                      isClosable: true,
                    });
                  } finally {
                    setIsEditing(false);
                  }
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        onClose={onDeleteClose}
        isOpen={isDeleteOpen}
        isCentered
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete linen variance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p> Are you sure? You can't undo this action afterwards.</p>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onDeleteClose}>Cancel</Button>
            {activeVarianceProduct && (
              <Button
                colorScheme="red"
                isLoading={isDeleting}
                loadingText="Deleting"
                onClick={async () => {
                  setIsDeleting(true);
                  try {
                    const { data } = await apiFetch<Stay[]>(
                      `linenvariances/${stay.Unit.Destination.code}/${stay.Unit.code}`,
                      {
                        stay_id: stay.id,
                        unit_id: stay.Unit.id,
                        reason: activeVarianceProduct.reason,
                        products: [
                          {
                            id: activeVarianceProduct.id,
                            product_code: activeVarianceProduct.productCode,
                            qty: 0,
                          },
                        ],
                      }
                    );
                    if (data.length === 1) {
                      toast({
                        title: `Linen variance deleted successfully.`,
                        status: "success",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                      });
                      onDeleteClose();
                    } else {
                      toast({
                        title: `Sorry there has been an error deleting the variance, please try again.`,
                        status: "error",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                  } catch (e) {
                    toast({
                      title: `Sorry there has been an error deleting the variance, please try again.`,
                      status: "error",
                      position: "top-right",
                      duration: 5000,
                      isClosable: true,
                    });
                  } finally {
                    setIsDeleting(false);
                  }
                }}
                ml={3}
              >
                Delete
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default VarianceList;
