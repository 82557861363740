import { Button, Flex, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { ProductListItem } from "../schemas/productListItem";
import { Unit } from "../types/api/unit";
import { ProductVarianceListItem } from "../schemas/productVarianceListItem";
import { useState } from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

interface Values {
  productQty: string;
}

interface EditVarianceProps {
  submitButtonText?: String;
  unit: Unit;
  product: ProductVarianceListItem;
  onDone: (data: ProductListItem) => void;
  isLoading: boolean | false;
}

const EditVariance = ({ unit, product, onDone, isLoading }: EditVarianceProps) => {
  const [qty, setQty] = useState<number>(product.qty);
  return (
    <Formik
      initialValues={{ username: "", qty: "" }}
      onSubmit={() => {
        if (product.productCode) {
          const editVariance = {
            product_code: product.productCode,
            qty: qty,
            reason: product.reason,
          };
          onDone(editVariance);
        }
      }}
    >
      <Form>
        <Stack spacing="5">
          <FormControl>
            <FormLabel htmlFor="product">Product</FormLabel>
            <Input id="product" type="text" value={product.productDescription} disabled={true} />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="qty">Quantity</FormLabel>
            <Input id="qty" type="number" min="1" value={qty} onChange={(e) => setQty(parseInt(e.target.value))} />
          </FormControl>
        </Stack>
        <Flex minWidth="100%" alignItems="center" justifyContent="flex-end" mt={9}>
          <Button
            type="submit"
            colorScheme="brand"
            rightIcon={<ArrowForwardIcon />}
            isLoading={isLoading}
            loadingText="Updating"
          >
            Update
          </Button>
        </Flex>
      </Form>
    </Formik>
  );
};

export default EditVariance;
