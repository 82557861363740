import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { useSelectedClient } from "../../hooks/useSelectedClient";
import CollectionItem from "./CollectionItem";
import { AddIcon } from "@chakra-ui/icons";
import CollectionsForm from "../CollectionsForm";
import { CollectionListItem } from "../../schemas/collectionListItem";
import { useLoaderData, useParams } from "react-router-dom";
import { Collection } from "../../types/api/collection";
import { useApiFetchFunction } from "../../hooks/useApiFetch";

const CollectionList = () => {
  const params = useParams();
  const collections = useLoaderData() as Collection[];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const apiFetch = useApiFetchFunction();
  const toast = useToast();
  const { selectedClient } = useSelectedClient();
  return (
    <Box w="100%" pb={4}>
      <Flex wrap={{ base: "wrap", lg: "nowrap" }} alignItems={"center"} justifyContent={"space-between"} mb={4}>
        <Heading size="lg">Collections</Heading>
        <Button size={"sm"} type="submit" colorScheme="green" rightIcon={<AddIcon />} onClick={onOpen}>
          Request new collection
        </Button>
      </Flex>
      <Box py={{ base: "4", sm: "8" }} px={{ base: "4", sm: "10" }} bg="white" boxShadow={"md"} borderRadius={"xl"}>
        {collections.length > 0 ? (
          <>
            <Flex
              display={{ base: "none", lg: "flex" }}
              wrap={{ base: "wrap", lg: "nowrap" }}
              gap={4}
              alignItems={"center"}
              justifyContent={"space-between"}
              pl={3}
              pb={2}
            >
              <Text as="b" width={{ base: "100%", lg: "20%" }}>
                Collection date
              </Text>
              <Text as="b" width={{ base: "100%", lg: "20%" }} lineHeight={"1.5em"} margin={0}>
                Number of boxes
              </Text>
              <Text as="b" width={{ base: "100%", lg: "10%" }}>
                Status
              </Text>
            </Flex>
            {collections.map((collection, i) => {
              return <CollectionItem key={i} collection={collection} />;
            })}
          </>
        ) : (
          <p>There are currently no collections.</p>
        )}
      </Box>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={"xl"}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Collection request </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {false ? (
              <Center width={{ base: "100%" }} marginX={"auto"}>
                <Spinner />
              </Center>
            ) : (
              <CollectionsForm
                onDone={async (collection) => {
                  try {
                    const { data } = await apiFetch<CollectionListItem[]>(`pickups/${selectedClient?.code}`, {
                      destinationCode: params.destinationCode,
                      readyDate: collection.readyDate,
                      boxQty: collection.boxQty,
                      status: 0,
                    });

                    if (data.length === 1) {
                      onClose();
                      toast({
                        title: `Collection request submitted.`,
                        status: "success",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                      });
                    } else {
                      toast({
                        title: `Sorry there has been a problem requesting a collection. Please try again or contact us.`,
                        status: "error",
                        position: "top-right",
                        duration: 7000,
                        isClosable: true,
                      });
                    }
                  } catch (e) {
                    toast({
                      title: `Sorry there has been a problem requesting a collection. Please try again or contact us.`,
                      status: "error",
                      position: "top-right",
                      duration: 7000,
                      isClosable: true,
                    });
                  } finally {
                  }
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CollectionList;
