import { Box, Center, FormErrorMessage, Heading, Spinner } from "@chakra-ui/react";

import { useSelectedClient } from "../hooks/useSelectedClient";
import { Unit } from "../types/api/unit";
import UnitItem from "./UnitItem";
import UnitList from "./UnitList";
import useAsyncApiFetch from "../hooks/useAsyncApiFetch";

const UnitSelect = ({ onSelect }: { onSelect: (unit: Unit) => void }) => {
  const { selectedClient } = useSelectedClient();
  const unitsApi = useAsyncApiFetch<Unit>(`units/${selectedClient?.code}`);

  return (
    <Box w="100%" pb={4}>
      {unitsApi.loading ? (
        <Center width={{ base: "100%", md: "50%" }} marginX={"auto"} mt={8}>
          <Spinner />
        </Center>
      ) : (
        <>
          <FormErrorMessage>{unitsApi.error}</FormErrorMessage>
          <Heading as="h4" size="lg" mb={4} textAlign={"center"}>
            Select unit
          </Heading>
          <Box width={{ base: "100%", md: "50%" }} marginX={"auto"}>
            <UnitList
              items={unitsApi.data.map((unit, i) => {
                return {
                  key: i,
                  item: <UnitItem item={unit} onClick={onSelect} />,
                };
              })}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default UnitSelect;
