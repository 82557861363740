export function dateFormatter(datetime: Date) {
  let date = datetime.toDateString();
  let time = "";
  //If hours is 0 assume its not been set so dont add it
  if (datetime.getHours() != 0) {
    time = `${String(datetime.getHours()).padStart(2, "0")}:${String(datetime.getMinutes()).padStart(2, "0")}`;
  }

  return `${date} ${time}`.trim();
}
