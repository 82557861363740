import { Box, Card, CardBody, Flex, Text } from "@chakra-ui/react";
import { Collection } from "../../types/api/collection";

const CollectionItem = ({ collection }: { collection: Collection }) => {
  let statusColor = "";
  switch (collection.status_name.toLowerCase()) {
    case "booked":
      statusColor = "blue.500";
      break;
    case "collected":
      statusColor = "green.500";
      break;

    default:
      statusColor = "orange.300";
      break;
  }

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <Box>
      <Card boxShadow={"none"} borderBottom={"1px solid red"} borderBottomColor={"gray.100"} borderRadius={0}>
        <CardBody p={0} pl={3} transition={{ ease: "anticipate" }} borderRadius={"var(--card-radius)"}>
          <Flex alignItems={"flex-start"} justifyContent={"space-between"} py={2}>
            <Flex width={{ base: "100%", sm: "90%" }} justifyContent={"flex-start"} wrap={"wrap"}>
              <Text fontSize={{ base: "sm", sm: "md" }} width={{ base: "100%", lg: "50%" }}>
                {new Date(collection.ready_date).toLocaleDateString("en-GB", dateOptions)}
                {(collection.tracking_no || collection.carrier) && (
                  <Text
                    fontSize={{ base: "xs", sm: "sm" }}
                    color={{ base: "GrayText" }}
                    width={{ base: "100%", lg: "50%" }}
                  >
                    Carrier: {collection.carrier ?? "-"}
                    <br /> Tracking No: {collection.tracking_no ?? "-"}
                  </Text>
                )}
              </Text>
              <Text
                fontSize={{ base: "xs", sm: "md" }}
                color={{ base: "GrayText", sm: "black.500" }}
                width={{ base: "100%", lg: "50%" }}
              >
                {collection.box_qty} boxes
              </Text>
            </Flex>
            <Box
              color="white"
              backgroundColor={statusColor}
              textTransform={"capitalize"}
              fontWeight={"bold"}
              py={"2px"}
              px={"5px"}
              fontSize={"xs"}
              borderRadius={"4"}
              width={{ base: "auto", lg: "10%" }}
              textAlign={"center"}
            >
              {collection.status_name}
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
};

export default CollectionItem;
