import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { Stay } from "../../schemas/stay";
import { ProductListItem } from "../../schemas/productListItem";
import useAsyncApiFetch from "../../hooks/useAsyncApiFetch";
import { Product } from "../../types/api/product";
import { useSelectedClient } from "../../hooks/useSelectedClient";

import { useApiFetchFunction } from "../../hooks/useApiFetch";
import { Unit } from "../../types/api/unit";
import { ItemInterface } from "../../types/items";
import LinenForm from "../LinenForm";

interface ChangeOverRejectsProps {
  stay: Stay;
  unit: Unit;
  products: ItemInterface[];
}

function ChangeOverVariances({ stay, unit, products }: ChangeOverRejectsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedClient } = useSelectedClient();
  const productApi = useAsyncApiFetch<Product>(`products/${selectedClient?.code}`);
  const toast = useToast();
  const apiFetch = useApiFetchFunction();

  return (
    <>
      <Button type="submit" colorScheme="blue" size={"sm"} rightIcon={<AddIcon />} onClick={onOpen}>
        Add linen variance
      </Button>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={"xl"}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add linen variance on booking - {stay.reference}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {productApi.loading ? (
              <Center width={{ base: "100%" }} marginX={"auto"}>
                <Spinner />
              </Center>
            ) : (
              <LinenForm
                submitButtonText={"Submit linen variance"}
                stay={stay}
                unit={unit}
                products={products}
                varianceForm={"variance"}
                onDone={async (products: ProductListItem[]) => {
                  try {
                    const { data } = await apiFetch<Stay[]>(
                      `linenvariances/${stay.Unit.Destination.code}/${stay.Unit.code}`,
                      {
                        stay_id: stay.id,
                        unit_id: stay.Unit.id,
                        products: products.filter(({ qty }) => {
                          if (qty !== 0) {
                            return true;
                          }
                          return false;
                        }),
                        reason: 0,
                      }
                    );
                    if (data.length === 1) {
                      toast({
                        title: `Linen variances recorded successfully.`,
                        status: "success",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                      });
                      onClose();
                    } else {
                    }
                  } catch (e) {
                  } finally {
                  }
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ChangeOverVariances;
