import { apiFetchFunction } from "../hooks/useApiFetch";
import { Stay } from "../schemas/stay";
import { Stay as APIStay, ProductVariance, StayProduct } from "../types/api/stay";

export const fitStay = (stay: APIStay) => {
  const statusNames = ["Cancelled", "Draft", "Booked", "Confirmed", "Completed"];
  const reasonNames = ["Variance", "Rejected"];
  return {
    id: stay.id,
    unitId: stay.Unit.id,
    Unit: stay.Unit,
    status: stay.status,
    statusName: statusNames[stay.status],
    reference: stay.reference,
    checkInTime: new Date(stay.check_in_time),
    checkOutTime: new Date(stay.check_out_time),
    cleaningTime: new Date(stay.cleaning_time),
    numberOfGuests: stay.number_of_guests,
    products: stay.products.map((product: StayProduct) => {
      return {
        product_code: product.Product.code,
        qty: product.qty,
      };
    }),
    productVariances: stay.product_variances.map((productVariance: ProductVariance) => {
      return {
        id: productVariance.id,
        productCode: productVariance.Product.code,
        reportedTime: new Date(productVariance.reported_time),
        reason: productVariance.reason,
        reasonName: reasonNames[productVariance.reason],
        productDescription: productVariance.Product.description,
        qty: productVariance.qty,
      };
    }),
  };
};

export const getStays = async (clientCode: string, apiFetch: apiFetchFunction, path?: string): Promise<Stay[]> => {
  const suffix = path === undefined ? "" : path;
  const { data } = await apiFetch<APIStay[]>(`stays/${clientCode}${suffix}`);
  if (data.length > 0) {
    return data.map(fitStay);
  }

  return [];
};
