import { useEffect, useReducer } from "react";

import {
  Box,
  SimpleGrid,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useSteps,
  useToast,
} from "@chakra-ui/react";

import { useApiFetchFunction } from "../hooks/useApiFetch";
import { useSelectedClient } from "../hooks/useSelectedClient";
import { ProductListItem } from "../schemas/productListItem";
import { Stay, staySchema } from "../schemas/stay";
import { Product } from "../types/api/product";
import { Unit } from "../types/api/unit";
import { matchGuard } from "../utils/types";
import LinenForm from "./LinenForm";
import StayForm from "./StayForm";
import UnitSelect from "./UnitSelect";
import useAsyncApiFetch from "../hooks/useAsyncApiFetch";
import StayCard from "./StayCard";

type CollectorState = {
  unit?: Unit;
  stay?: Stay;
};

type CollectorActions =
  | { type: "setUnit"; unit: Unit }
  | { type: "setStay"; stay: Stay }
  | { type: "clearStay" }
  | { type: "done" };

const StayCollector = () => {
  const toast = useToast();
  const { selectedClient } = useSelectedClient();
  const apiFetch = useApiFetchFunction();
  const reducer = (state: CollectorState, action: CollectorActions): CollectorState => {
    switch (action.type) {
      case "setUnit":
        return { ...state, unit: action.unit };
      case "setStay":
        return { ...state, stay: action.stay };
      case "clearStay":
        return { ...state, stay: undefined };
      case "done":
        return {};
      default:
        return matchGuard(action);
    }
  };
  const [collectorState, dispatch] = useReducer(reducer, {});

  const productApi = useAsyncApiFetch<Product>(`products/${selectedClient?.code}`);

  const steps = [
    {
      title: "Select Unit",
      panel: () => (
        <UnitSelect
          onSelect={(unit: Unit) => {
            dispatch({ type: "setUnit", unit });
          }}
        />
      ),
    },
    {
      title: "Enter booking",
      panel: () =>
        collectorState.unit ? (
          <StayForm
            unit={collectorState.unit}
            onCancel={() => {
              dispatch({ type: "done" });
            }}
            onDone={(data: Stay) => {
              if (collectorState.unit) {
                dispatch({ type: "setStay", stay: data });
              }
            }}
          />
        ) : (
          ""
        ),
    },
    {
      title: "Confirm linen",
      panel: () =>
        collectorState.stay && collectorState.unit && !productApi.loading ? (
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <StayCard stay={collectorState.stay} unit={collectorState.unit} />
            <Box
              py={{ base: "4", sm: "8" }}
              px={{ base: "4", sm: "10" }}
              bg="white"
              boxShadow={"md"}
              borderRadius={"xl"}
            >
              <LinenForm
                stay={collectorState.stay}
                unit={collectorState.unit}
                products={productApi.data.map((product) => {
                  return { id: product.code, name: product.description };
                })}
                onDone={async (products: ProductListItem[]) => {
                  try {
                    if (selectedClient === undefined) {
                      // setFormError("Unknown client.");
                    } else {
                      if (collectorState.stay) {
                        // set status to confirmed
                        collectorState.stay.status = 3;
                      }
                      const { data } = await apiFetch<Stay[]>(`stays`, {
                        ...collectorState.stay,
                        products: products,
                      });

                      if (data.length === 1) {
                        const insertedStay: Stay = data[0]; // staySchema.cast(data[0]);
                        const referenceText = insertedStay.reference ? ` with reference ${insertedStay.reference}` : "";
                        toast({
                          title: `Booking created${referenceText}`,
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                        dispatch({ type: "done" });
                      } else {
                        // setFormError("No single matching unit.");
                      }
                    }
                  } catch (e) {
                    // setFormError("Error looking up accomodation unit.");
                  } finally {
                    // setIsLoading(false);
                  }
                }}
              />
            </Box>
          </SimpleGrid>
        ) : (
          ""
        ),
    },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const activeStepText = steps[activeStep].title;

  useEffect(() => {
    if (collectorState.unit === undefined) {
      setActiveStep(0);
    } else {
      if (collectorState.stay === undefined) {
        setActiveStep(1);
      } else {
        setActiveStep(2);
      }
    }
  }, [collectorState, setActiveStep]);
  return (
    <>
      <Box p={3} bg="white" boxShadow={"md"} borderRadius={"lg"} mb={8}>
        <Stepper maxW={600} index={activeStep} flexWrap={"wrap"} colorScheme="brand">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
              </StepIndicator>
              <Box hideBelow="md" flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        <Text hideFrom="md" pt={2}>
          Step {activeStep + 1}: <b>{activeStepText}</b>
        </Text>
      </Box>
      {steps[activeStep].panel()}
    </>
  );
};

export default StayCollector;
