import { ReactNode } from "react";

import { StackProps } from "@chakra-ui/react";
import ItemList, { ItemListItemProps } from "./ItemList";

interface UnitListProps<T> extends StackProps {
  items: ItemListItemProps<T>[];
}

const UnitList = <T extends ReactNode>({ items, ...stackProps }: UnitListProps<T>) => {
  return (
    <ItemList items={items} {...stackProps} />
  );
};

export default UnitList;
